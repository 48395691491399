import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import Context from "../../mainframe/Context";
import doFetch, { getServerUrl } from "../../../util/NetworkUtil";
import ButtonFilter from "./ButtonFilter";
import { useSearchParams } from "react-router-dom";
import { copyToClipboard } from "../../../util/Util";
import { attributes, createUseCase } from "../../elements/UseCase";
import UseCaseModal from "../../modals/UseCaseModal";

const UseCaseFilter = ({ filter, setFilter, notificationManager }) => {
    /**Handles the display of the data in a container **/

    let [context] = useContext(Context);
    let [selectionOptions, setSelectionOptions] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let [searchParams, setSearchParams] = useSearchParams();
    let [createUseCaseOpen, setCreateUseCaseOpen] = useState(false);





    useEffect(() => {
        const controller = new AbortController();
        fetchUseCaseOptions(controller)

        return () => {
            console.log("discarding")

            //discard old requests
            controller.abort()
        }
    }, [filter])

    let lastUpdated;


    const fetchUseCaseOptions = (controller) => {
        console.log("fetching", filter)

        let now = new Date();
        lastUpdated = now

        let queryString = Object.keys(filter).map(k => `${encodeURI(k)}=${encodeURI(filter[k])}`).join("&")


        doFetch({
            url: `${getServerUrl()}/usecases/attributefilter?` + queryString,
            requestObject: {
                signal: controller.signal,
            },
            loadingFn: setIsLoading,
            dataFn: response => {
                if (response) setSelectionOptions(response);
            },
            errorText: "Not found, or get failed."
        });
    };


    /**
     * Copy content of the Filter to the clipboard
     */
    const copyFilterToClipBoard = async () => {
        var text = JSON.stringify(filter)

        await copyToClipboard(text)
        let notification = notificationManager.createNotification("Kopiert", <span className="material-symbols-outlined color-secondary">check</span>, JSON.stringify(filter, null, 2))
        setTimeout(() => notificationManager.dismiss(notification), 4000)

    }

    /**
  * Copy content of the Filter to the clipboard
  */
    const createNewUseCase = () => {
        setCreateUseCaseOpen(true)

    }


    /**
     * Updates the filter/ handles _Like integration if the filter is already set to the value, 
     * then this is interpreted as removing the filter
     * @param {*} key 
     * @param {*} value 
     */
    const updateFilter = (key, value) => {
        let tmpFilter = { ...filter };

        if (key.includes("_like")) {
            // remove non _like
            delete tmpFilter[key.replace("_like", "")];
        } else {
            // remove _like
            delete tmpFilter[key + "_like"];
        }

        if (filter[key] === value || value === null) {
            delete tmpFilter[key];
        } else {
            tmpFilter[key] = value;
        }

        setFilter(tmpFilter);
        console.log(tmpFilter);
    };

    if (isLoading && selectionOptions == null) return <Spinner type="grow" size="sm"></Spinner>
    return (
        <>
            <div className="dataset-selector-reset" style={{ marginRight: "50px" }}>
                <Button id="create-usecase" size="sm" onClick={() => createNewUseCase()} color="white" >
                    <span className="material-symbols-outlined" style={{ fontSize: "16px", marginTop: "2px" }}>
                        docs_add_on
                    </span>
                </Button>
                <UncontrolledTooltip placement="top" target="create-usecase">
                    <b>Create new Usecase</b>
                </UncontrolledTooltip>
                {" "}
                <Button size="sm" onClick={() => copyFilterToClipBoard()} color="white" >
                    <span className="material-symbols-outlined" style={{ fontSize: "16px", marginTop: "2px" }}>
                        content_copy
                    </span>
                </Button>
                {" "}
                {Object.keys(filter).length > 0 && Object.keys(filter).filter(k => filter[k]).length > 0 ? (
                    <Button size="sm" onClick={() => setFilter({})} color="undo" >
                        <span className="material-symbols-outlined" style={{ fontSize: "16px", marginTop: "2px" }}>undo</span>
                    </Button>
                ) : (
                    <></>
                )}


            </div>
            {" "}

            <div className="dataset-selector-buttons">
                <Row style={{ marginBottom: "14px" }}>

                    <Col >
                        < ButtonFilter key={"btnfitler_restriction"} attrName={"restriction"} options={selectionOptions["restriction"] ? selectionOptions["restriction"] : []} filter={filter} updateFilter={updateFilter} showSearchField={false} isLoading ></ButtonFilter>
                    </Col>
                    <Col style={{ position: "relative", left: "-520px" }} >
                        < ButtonFilter key={"btnfitler_dataType"} attrName={"dataType"} options={selectionOptions["dataType"] ? selectionOptions["dataType"] : []} filter={filter} updateFilter={updateFilter} showSearchField={false} isLoading={isLoading} ></ButtonFilter>
                    </Col>

                </Row>

                {attributes.map(attrObj => {
                    if (attrObj.name === "dataType") return
                    if (attrObj.name === "restriction") return

                    let options = selectionOptions[attrObj.name]
                    if (!options) options = []

                    else return < ButtonFilter
                        key={"btnfitler_" + attrObj.name}
                        attrName={attrObj.name}
                        options={options}
                        filter={filter}
                        updateFilter={updateFilter}
                        isLoading={isLoading} ></ButtonFilter>

                })}
            </div >
            {createUseCaseOpen ? <UseCaseModal
                onClose={() => { setCreateUseCaseOpen(false) }}
                mode="create"
                onSubmit={(useCases) => { createUseCase(useCases[0], () => setFilter({ ...filter })) }}

            ></UseCaseModal> : <></>}

        </>
    );
};

export default UseCaseFilter;
