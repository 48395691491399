import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col, UncontrolledTooltip } from "reactstrap";
import AreYouSureModal from "./AreYouSureModal";
import Context from "../mainframe/Context";
import UseCaseForm from "../forms/UseCaseForm";
import { attributes } from "../elements/UseCase";
import UseCaseAttribute from "../elements/UseCaseAttribute";
import InputField from "../elements/InputField";

const createMaster = useCases => {
    let master = {};
    let lead = useCases[0];
    Object.keys(lead).forEach(key => {
        if (useCases.filter(uc => uc[key] !== undefined && uc[key] === lead[key]).length === useCases.length) master[key] = lead[key];
    });
    return master;
};

const UseCaseModal = props => {
    let [context] = useContext(Context);

    let { useCases = [{ dataType: "ocd", restriction: "general" }], selectionOptions = { dataType: ["ocd", "oncd"], restriction: ["general", "intraday"] }, onClose, onSubmit, onDelete, mode } = props;


    let [updatedUseCases, setUpdatedUseCases] = useState(useCases);
    let [updatedUseCasesValid, setUpdatedUseCasesValid] = useState(useCases.map(u => { return {} }));
    let [masterUseCase, setMasterUseCases] = useState(createMaster(useCases));
    let [masterUseCaseValid, setMasterUseCasesValid] = useState({});
    let [isOpen, setIsOpen] = useState(true);
    // let [dropDownStates, setDropDownStates] = useState({});
    let [areYouSureModalState, setAreYouSureModalState] = useState(false);

    const toggle = () => {
        if (isOpen) onClose(updatedUseCases);
        setIsOpen(!isOpen);
    };

    // /**
    //  * Updates a field of the usecase
    //  * @param {String} key
    //  * @param {String} value
    //  */
    // const updateUsecase = (key, value) => {
    //     setUpdatedUseCase({ ...updatedUseCase, [key]: value });
    // };

    const forbiddenCharsPattern = /[!.,;:_*\/|äöüÄÖÜ]+$/
    const isAttrValid = (attr, attrValue, validationObj) => {
        let isValid = !forbiddenCharsPattern.test(attrValue);
        //do the validity check here
        validationObj[attr] = isValid;
        return isValid
    }

    const updateUseCaseInArray = (index, useCase) => {
        let newArray = [...updatedUseCases];
        newArray[index] = useCase;
        setUpdatedUseCases(newArray);
        console.log(newArray)
    };

    const updateMasterUseCase = newMaster => {
        setMasterUseCases(newMaster);

        let changedKeys = Object.keys(newMaster).filter(key => newMaster[key]);
        let newArray = [...updatedUseCases];
        newArray.forEach(uc => changedKeys.forEach(key => (uc[key] = newMaster[key])));

        setUpdatedUseCases(newArray);
    };

    const submit = () => {
        if (updatedUseCasesValid.every(ucValid => Object.values(ucValid).every(v => v))) {
            onSubmit(updatedUseCases);
            toggle();
        } else {
            alert("Some Usecases are not valid")

        }

    };

    const doDelete = () => {
        onDelete(updatedUseCases);
        toggle();
    };


    const generateHeaderLine = () => {
        return <tr>

            {
                useCases.length > 1 ?
                    <th style={{ "text-align": "left", position: "sticky", left: 0, backgroundColor: "var(--color-surface)", borderRight: "1px solid var(--color-border)", borderBottom: "1px solid var(--color-border)" }}>Master (edits all)</th>
                    : <></>
            }

            {
                useCases.length > 1 ?
                    useCases.map((uc, i) => {
                        return <th style={{ "text-align": "left", padding: "5px 15px", borderBottom: "1px solid var(--color-border)" }}> {i}</th >

                    }) : <></>
            }
        </tr >
    }

    /**
     * The masterUseCase sets the attrs for all attrss
     * @returns
     */
    const generateMasterUseCase = (attribute) => {
        if (useCases.length > 1)
            return (
                <>
                    <td xs="3" style={{ minWidth: "330px", paddingRight: "15px", position: "sticky", left: 0, backgroundColor: "var(--color-surface)", borderRight: "1px solid var(--color-border)" }}>

                        <InputField
                            key={"masterinput_" + attribute}
                            additionalClasses={"usecase-attribute-label"}
                            label={attribute}
                            optionsHeader={attribute}
                            val={masterUseCase[attribute]}
                            options={selectionOptions[attribute]}
                            updateValue={value => { updateMasterUseCase({ ...masterUseCase, [attribute]: value }) }}
                            isValid={isAttrValid(attribute, masterUseCase[attribute], masterUseCaseValid)}
                            disabled={false}
                        />
                    </td>
                </>
            );
        else return <></>
    };

    /**
     * The masterUseCase sets the attrs for all attrss
     * @returns
     */
    const generateSingleUseCase = (attribute) => {
        return <Col xs={useCases.length > 1 ? "3" : "12"}>
            <InputField
                key={"input_" + attribute}
                additionalClasses={"usecase-attribute-label"}
                label={attribute}
                optionsHeader={attribute}
                val={updatedUseCases[0][attribute]}
                options={selectionOptions[attribute]}
                updateValue={value => updateUseCaseInArray(0, { ...updatedUseCases[0], [attribute]: value })}
                isValid={isAttrValid(attribute, useCases[0][attribute], updatedUseCasesValid[0])}
                disabled={false}
            />
        </Col>

    };

    /**
    * The masterUseCase sets the attrs for all attrss
    * @returns
    */
    const generateMultiUseCase = (attribute) => {
        return useCases.map((useCase, i) =>
            <td style={{ padding: "5px 15px", whiteSpace: "nowrap" }}>{useCase[attribute]}</td>
        )
    };

    return (
        <Modal toggle={toggle} isOpen={isOpen} className={context.theme} size={updatedUseCases.length == 1 ? "md" : updatedUseCases.length < 2 ? "lg" : "xl"}>
            <ModalHeader toggle={toggle}>UseCase {useCases.length > 1 ? `(${useCases.length})` : ""}</ModalHeader>
            <ModalBody style={{ fontSize: "13px", }}>
                <div style={{ overflow: "auto", padding: "0px 0px 10px 0px" }}>

                    <table style={{}}>

                        {generateHeaderLine()}


                        {attributes.map(attribute => <><tr>
                            {generateMasterUseCase(attribute.name)}
                            {useCases.length > 1 ? generateMultiUseCase(attribute.name) : generateSingleUseCase(attribute.name)}



                        </tr></>)}
                    </table>
                </div>



                {/* {updatedUseCases.map((updatedUC, index) => (
                        <Col xs={useCases.length > 1 ? "3" : "12"}>
                            {useCases.length > 1 ? <p style={{ "text-align": "right" }}>UseCase: {index + 1}</p> : <></>}
                            <UseCaseForm
                                useCase={updatedUC}
                                validation={updatedUseCasesValid[index]}
                                updateUseCase={uc => updateUseCaseInArray(index, uc)}
                                selectionOptions={selectionOptions}
                                showLabel={useCases.length === 1}
                                showCategory={!onDelete}
                            />
                        </Col>
                    ))} */}
            </ModalBody>
            <ModalFooter>
                {onDelete !== undefined ? (
                    <>
                        <Button color="" className="danger" onClick={() => setAreYouSureModalState(true)}>
                            Delete
                        </Button>
                        <AreYouSureModal onConfirm={doDelete} onCancel={() => setAreYouSureModalState(false)} isOpen={areYouSureModalState} />
                    </>
                ) : (
                    <div />
                )}
                <Button color="primary" onClick={toggle}>
                    Cancel
                </Button>
                <Button onClick={submit}>{mode === "update" ? "Update" : "Create"}</Button>{" "}
            </ModalFooter>
        </Modal >
    );
};

export default UseCaseModal;
